// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faFacebookF      as fabFaFacebookF      } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram      as fabFaInstagram      } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faPinterestP     as fabFaPinterestP     } from "@fortawesome/free-brands-svg-icons/faPinterestP";

import { faBars           as fasFaBars           } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faSearch         as fasFaSearch         } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTimes          as fasFaTimes          } from "@fortawesome/pro-solid-svg-icons/faTimes";

import { faAngleDown      as falFaAngleDown      } from "@fortawesome/pro-light-svg-icons/faAngleDown";
import { faAngleLeft      as falFaAngleLeft      } from "@fortawesome/pro-light-svg-icons/faAngleLeft";
import { faAngleRight     as falFaAngleRight     } from "@fortawesome/pro-light-svg-icons/faAngleRight";

/**
 * Add brand icons
 */
library.add(fabFaFacebookF, fabFaInstagram, fabFaPinterestP);

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaQuestionCircle, fasFaSearch, fasFaTimes);

/**
 * Add light icons
 */
library.add(falFaAngleDown, falFaAngleLeft, falFaAngleRight);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
